import { createl10n } from 'app/core/i18n';
import IForgotPasswordModel from './IForgotPasswordModel';

const l10n = createl10n('app/modules/authentication/components/forgot-password');

// Taken from: http://stackoverflow.com/a/46181/127497
const EMAIL_VALIDATOR_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validate = (values: any): any => {

    const validationResult: IForgotPasswordModel = {
        username: validateUsername(),
        emailAddress: validateEmailAddress(),
    };

    return validationResult;

    ////////////////////

    function validateUsername() {
        const { username } = values;
        if (!username) {
            return l10n('fields.username.errors.required');
        }

        return null;
    }

    function validateEmailAddress() {
        const { emailAddress } = values;
        if (!emailAddress) {
            return l10n('fields.emailAddress.errors.required');
        }

        const isValidEmailAddress = EMAIL_VALIDATOR_REGEX.test(emailAddress);
        if (!isValidEmailAddress) {
            return l10n('fields.emailAddress.errors.invalid');
        }

        return null;
    }
};

export default validate;