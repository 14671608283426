import { createl10n } from 'app/core/i18n';
import ILoginModel from './ILoginModel';

const l10n = createl10n('app/modules/authentication/components/login');

const validate = (
    values: ILoginModel
): {
    username: string;
    password: string;
    rememberMe: string;
    token: string;
} => {
    return {
        username: validateUsername(),
        password: validatePassword(),
        rememberMe: null,
        token: validateToken(),
    };

    ////////////////////

    function validateUsername() {
        const { username } = values;
        if (!username) {
            return l10n('fields.username.errors.required');
        }

        return null;
    }

    function validatePassword() {
        const { password } = values;
        if (!password) {
            return l10n('fields.password.errors.required');
        }

        return null;
    }

    function validateToken() {
        const { token } = values;
        if (!token) {
            return l10n('fields.password.errors.required');
        }

        return null;
    }
};

export default validate;
