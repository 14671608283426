import { createl10n } from 'app/core/i18n';
import IChangePasswordModel from './IChangePasswordModel';

const l10n = createl10n('app/modules/authentication/components/change-password');

const validate = (values: IChangePasswordModel) => {

    const validationResult = {
        password: validatePassword(values.password),
        passwordConfirm: validatePasswordConfirm(values.password, values.passwordConfirm),
    };

    return validationResult;

    ////////////////////

    function validatePassword(password) {
        if (!password) {
            return l10n('fields.password.errors.required');
        }

        const isPasswordValid =
            validateMinLength(password) &&
            validateContainsLowercaseLetter(password) &&
            validateContainsUppercaseLetter(password) &&
            validateContainsNumber(password) &&
            validateContainsSpecialCharacter(password);

        if (!isPasswordValid) {
            return l10n('fields.password.errors.invalid');
        }

        return null;
    }

    function validatePasswordConfirm(password, passwordConfirm) {
        if (!passwordConfirm) {
            return l10n('fields.password.errors.required');
        }

        if (passwordConfirm !== password) {
            return l10n('fields.password.errors.same');
        }

        return null;
    }
};

function validateMinLength(password: string = '') {
    return password.length >= 14;
}

function validateContainsLowercaseLetter(password: string = '') {
    return /[a-z]/.test(password);
}

function validateContainsUppercaseLetter(password: string = '') {
    return /[A-Z]/.test(password);
}

function validateContainsSpecialCharacter(password: string = '') {
    return /[\s!'"`#$%&*+-./\\:;<=>?@^_{}|~\[\]]/.test(password);
}

function validateContainsNumber(password: string = '') {
    return /\d/.test(password);
}

export default validate;

export {
    validateMinLength,
    validateContainsLowercaseLetter,
    validateContainsUppercaseLetter,
    validateContainsSpecialCharacter,
    validateContainsNumber,
};