import { sanitizeQueryString } from 'app/core/History';
import { IGlobalState } from 'app/core/redux/store';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { ChangePassword, ForgotPassword, Login } from 'app/modules/authentication/components';
import { verifyLoginCookie } from 'app/modules/authentication/components/login/Login.redux';
import { PageHeader, Spinner } from 'app/components';
import { translationLoader } from 'app/core/i18n';
import RequestState from 'app/core/redux/RequestState';
import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { Footer } from 'app/shell';

import './Main.less';
import { UnknownUser } from 'app/modules/authentication/components/unknown-user';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const LoadingTranslations = () => {
    return (
        <main id="main-loading" className="container">
            <Spinner blank={true} />
        </main>
    );
};

const Verifying = () => {
    return (
        <main id="main-loading" className="container">
            <PageHeader />
            <Spinner />
        </main>
    );
};

const RouteContainer = () => {
    const location = useLocation();
    const loginUrl = `/login${sanitizeQueryString(location.search)}`;

    return (
        <div id="main-content" className="container">
            <TransitionGroup>
                <CSSTransition key={location.key} classNames="animated-page" exit={false} timeout={1000}>
                    <Switch location={location}>
                        <Route path="/forgot-password">
                            <ForgotPassword />
                        </Route>
                        <Route path="/change-password">
                            <ChangePassword />
                        </Route>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/unknown-user">
                            <UnknownUser />
                        </Route>

                        <Redirect to={loginUrl} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
};

const Main = (props: StateProps & DispatchProps) => {
    const { isVerifyingLoginCookie, verifyLoginCookie } = props;
    const [translationsLoaded, setTranslationsLoaded] = useState(false);

    useEffect(() => verifyLoginCookie, [verifyLoginCookie]);

    useEffect(() => {
        const waitForTranslations = async () => {
            await translationLoader;
            setTranslationsLoaded(true);
        };

        // noinspection JSIgnoredPromiseFromCall
        waitForTranslations();
    }, [translationLoader]);

    return (
        <>
            {!translationsLoaded && <LoadingTranslations />}
            {translationsLoaded && (
                <>
                    {isVerifyingLoginCookie && <Verifying />}
                    {!isVerifyingLoginCookie && <RouteContainer />}
                    <Footer />
                </>
            )}
        </>
    );
};

const mapStateToProps = (state: IGlobalState) => {
    const { verifyingLoginCookieRequestState } = state.authentication.login;

    return {
        isVerifyingLoginCookie: verifyingLoginCookieRequestState === RequestState.Pending,
    };
};

const mapDispatchToProps = { verifyLoginCookie };

export default connect(mapStateToProps, mapDispatchToProps)(Main);
export { Main };
