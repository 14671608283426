import { FluidContainer } from 'app/components';
import { createl10n } from 'app/core/i18n';
import React from 'react';

const l10n = createl10n('app/modules/authentication/components/unknown-user');

export function UnknownUser(): JSX.Element {
    return (
        <FluidContainer heading={l10n('heading')}>
            <p>{l10n('labels.ssoProviderFound')}</p>
            <p>{l10n('labels.contactUs')}</p>
            <ul>
                <li>
                    {l10n('actions.email')}:&nbsp;
                    <a href="mailto:helpdesk@envirosuite.com">helpdesk@envirosuite.com</a>
                </li>
                <li>
                    {l10n('actions.contact')}:&nbsp;
                    <a href="https://envirosuite.com/contact" target="_blank" rel="noreferrer">
                        https://envirosuite.com/contact
                    </a>
                </li>
                <li>
                    {l10n('actions.support')}:&nbsp;
                    <a href="https://support.envirosuite.com" target="_blank" rel="noreferrer">
                        https://support.envirosuite.com
                    </a>
                </li>
            </ul>
        </FluidContainer>
    );
}
