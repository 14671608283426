import { createAction, handleActions } from 'redux-actions';
import AuthenticationService from 'app/modules/authentication/AuthenticationService';
import IForgotPasswordModel from './IForgotPasswordModel';
import RequestState from 'app/core/redux/RequestState';
import { SubmissionError } from 'redux-form';

const ActionTypes = {
    RESET_PASSWORD_REQUEST_STATE_CHANGE: 'es/auth/password/RESET_PASSWORD_REQUEST_STATE_CHANGE',
};

interface IForgotPasswordState {
    passwordResetRequestState: RequestState;
}

const initialState: IForgotPasswordState = {
    passwordResetRequestState: RequestState.Idle,
};

//////////////////// RESET PASSWORD

const resetPasswordRequestStateChange = createAction(ActionTypes.RESET_PASSWORD_REQUEST_STATE_CHANGE);
const clearResetPasswordRequestStateChange = createAction(ActionTypes.RESET_PASSWORD_REQUEST_STATE_CHANGE, () => RequestState.Idle);

function resetPassword(formData: IForgotPasswordModel): any {
    return async dispatch => {
        dispatch(resetPasswordRequestStateChange(RequestState.Pending));

        const result = await AuthenticationService.resetPassword(formData);

        if (result.isSuccess()) {
            dispatch(resetPasswordRequestStateChange(RequestState.Success));
        } else {
            dispatch(resetPasswordRequestStateChange(RequestState.Fail));
            throw new SubmissionError({ _error: result.messages as any });
        }
    };
}

//////////////////// REDUCER

const reducer = handleActions({
    [resetPasswordRequestStateChange]: handleRequestStateChange,
    [clearResetPasswordRequestStateChange]: handleRequestStateChange,
}, initialState);

function handleRequestStateChange(state, action) {
    return {
        ...state,
        passwordResetRequestState: action.payload,
    };
}

//////////////////// EXPORTS

export default reducer;
export {
    resetPassword,
    clearResetPasswordRequestStateChange as clearForm,
    IForgotPasswordState,
};

// For testing only
export const internal = {
    resetPasswordRequestStateChange,
};