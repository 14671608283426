import React from 'react';
import { ReactSVG } from 'react-svg';

import './PageHeader.less';
import logo from './Logo.svg';

const PageHeader = ({ title }: { title?: string }) => {
    return (
        <div className="pageHeader">
            <ReactSVG src={logo} />

            {title && <h2 className="text-center">{title}</h2>}
        </div>
    );
};

export default PageHeader;