import React from 'react';
import classNames from 'classnames';

import PageHeader from 'app/components/page-header/PageHeader';
import './FluidContainer.less';
import CSSTransition from 'react-transition-group/CSSTransition';

const FluidContainer = ({ cols = 6, heading, instructions, children }: {
    cols?: number,
    heading: string,
    instructions?: string,
    children: any,
}) => {
    const offsetCols = (12 - cols) / 2;
    const containerClasses = classNames(`col-md-${cols} col-md-offset-${offsetCols}`, {
        noInstructions: !instructions
    });

    return (
        <div className="container-fluid">
            <PageHeader title={heading}/>

            <div className={containerClasses}>
                {instructions && <p className="instructions" dangerouslySetInnerHTML={{ __html: instructions }}/>}
                {children}
            </div>
        </div>
    );
};

export default FluidContainer;