import AppSettings from 'app/core/AppSettings';
import i18n, { TOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';

const t = i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        load: 'languageOnly',
        preload: ['en'],
        backend: {
            loadPath: (lngs, namespaces) => AppSettings.buildAppUrl(`_i18n/${namespaces}/_i18n/l10n.${lngs}.json`),
        },
        detection: {
            // order and from where user language should be detected
            order: ['navigator'],
        },
        react: {
            wait: true,
            bindI18n: 'languageChanged loaded',
            bindStore: 'added removed',
            nsMode: 'default',
        },
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

export { t as translationLoader };

export const createl10n = (namespace: string | string[], prefix?: string) => {
    i18n.loadNamespaces(namespace).then(() => console.debug(`Loaded translations namespace: ${namespace}`));

    return (key: string, options?: TOptions) => {
        const keyParts = [];

        const hasNamespace = /:/.test(key);
        if (!hasNamespace) {
            keyParts.push(Array.isArray(namespace) ? namespace[0] : namespace);
            keyParts.push(':');
        }

        if (prefix) {
            keyParts.push(`${prefix}.`);
        }

        keyParts.push(key);
        const qualifiedKey = keyParts.join('');

        return i18n.t(qualifiedKey, options);
    };
};
