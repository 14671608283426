import HttpResponse from './HttpResponse';

import progressBar from 'react-nprogress';
import ReactGA from 'react-ga';

////////////////////

const NO_CONTENT = { data: null, messages: [] };

function get(qualifiedUrl) {
    const fetchOptions = {
        method: 'GET',
    };

    return fetchAsync(qualifiedUrl, fetchOptions);
}

function post(qualifiedUrl, body, options = {}) {
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),

        ...options,
    };

    return fetchAsync(qualifiedUrl, fetchOptions);
}

async function fetchAsync(qualifiedUrl, fetchOptions) {
    progressBar.start();

    try {
        const options = {
            credentials: 'include',
            ...fetchOptions,
        };

        const fetchResult = await fetch(qualifiedUrl, options);

        const responseBody = fetchResult.status === 204 || fetchResult.statusText === 'No Content'
            ? NO_CONTENT
            : await fetchResult.json();

        return new HttpResponse(fetchResult.status, responseBody);

    } catch (error) {
        return new HttpResponse(-1, null, error);
    } finally {
        progressBar.done();
    }
}

async function trackAndPost(eventCategory: string, qualifiedUrl: string, body: any) {
    let actionResult = 'failure';
    try {
        const response = await post(qualifiedUrl, body);
        if (response.isSuccess()) {
            actionResult = 'success';
        }

        return response;
    } finally {
        ReactGA.event({ category: eventCategory, action: actionResult });
    }
}

export default { get, post, trackAndPost };
