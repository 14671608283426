import React from 'react';

import { InlineSVG } from 'app/components';

import arrow from './Arrow.svg';
import './ActionButton.less';

const ActionButton = ({ label, disabled, onClick }: {
    label: string,
    disabled?: boolean,
    onClick?: (event) => void,
}) => {
    const buttonProperties = {
        disabled,
        className: 'actionButton',
        type: onClick ? 'button' : 'submit',
    } as any;

    if (onClick) {
        buttonProperties.onClick = onClick;
    }

    return (
        <button {...buttonProperties}>
            <InlineSVG path={arrow} className="arrow" />
            {label}
        </button>
    );
};

export default ActionButton;