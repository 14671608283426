import React from 'react';
import { Link } from 'react-router-dom';

const LinkButton = ({ label, to, disabled }: {
    label: string,
    to: string,
    disabled?: boolean,
}): JSX.Element => (
    <div className="row">
        <div className="col-xs-12 text-center">
            {!disabled && <Link to={to}>{label}</Link>}
            {disabled && <span>{label}</span>}
        </div>
    </div>
);

export default LinkButton;