import http from 'app/core/services/http';
import HttpResponse from 'app/core/services/HttpResponse';
import ILoginModel from './components/login/ILoginModel';
import IForgotPasswordModel from './components/forgot-password/IForgotPasswordModel';

import AppSettings from 'app/core/AppSettings';

/**
 * Is the user logged in?
 * @returns {Promise<HttpResponse>}
 */
function isLoggedIn() {
    return http.get(AppSettings.buildApiUrl('security/login'));
}

/**
 * Logs the user in.
 * @param loginModel
 * @returns {Promise<HttpResponse|HttpResponse>}
 */
async function login(loginModel: ILoginModel): Promise<HttpResponse> {
    return http.trackAndPost('security.login', AppSettings.buildApiUrl('security/login'), loginModel);
}

/**
 * Reset the user's password.
 * @param resetPasswordModel
 * @returns {Promise<HttpResponse|HttpResponse>}
 */
async function resetPassword(resetPasswordModel: IForgotPasswordModel): Promise<HttpResponse> {
    return http.trackAndPost('security.reset_password', AppSettings.buildApiUrl('security/generate-password-reset-token'), resetPasswordModel);
}

/**
 * Generate a new password token that is sent to the user's email address.
 * @param resetPasswordModel
 * @returns {Promise<HttpResponse|HttpResponse>}
 */
async function generateNewPasswordToken(passwordToken: string): Promise<HttpResponse> {
    return http.post(AppSettings.buildApiUrl('security/generate-new-password-token'), passwordToken);
}

/**
 * Change the user's password.
 * @param newPassword
 * @param username
 * @param oldPassword
 * @param passwordToken
 * @returns {Promise<HttpResponse>}
 */
async function changePassword(newPassword: string, username?: string, oldPassword?: string, passwordToken?: string): Promise<HttpResponse> {
    return http.trackAndPost('security.change_password', AppSettings.buildApiUrl('security/authenticate-new-password'), {
        username,
        oldPassword,
        password: newPassword,
        passwordToken,
    });
}

/**
 * Verify the password change token is valid and hasn't expired.
 * @param passwordToken
 * @returns {Promise<HttpResponse>}
 */
async function verifyPasswordToken(passwordToken: string): Promise<HttpResponse> {
    return http.post(AppSettings.buildApiUrl('security/validate-password-token'), passwordToken);
}

export default {
    isLoggedIn,
    login,
    resetPassword,
    changePassword,
    verifyPasswordToken,
    generateNewPasswordToken,
};
