import React from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import AppSettings from 'app/core/AppSettings';
import { createl10n } from 'app/core/i18n';

import styles from './Footer.less';

const l10n = createl10n('app/shell/footer');

const Footer = () => {
    const privacyUrl = 'https://envirosuite.com/privacy';
    const showInternetSecurityNumber = AppSettings.internetSecurityNumber && AppSettings.internetSecurityNumberText;
    const internetSecurityRegistrationLink = `http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${AppSettings.internetSecurityNumber}`;

    return (
        <>
            <CookieConsent
                containerClasses="footer-analytics-consent"
                location="bottom"
                buttonText={l10n('ok')}
                cookieName="heap-analytics-cookie-consent"
            >
                {l10n('message')}&nbsp;
                <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
                    {privacyUrl}
                </a>
            </CookieConsent>
            <footer className={`footer ${styles.footer}`}>
                <a href="https://envirosuite.com/">www.envirosuite.com</a>
                {AppSettings.icpCertificate && (
                    <a href="https://www.beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
                        {AppSettings.icpCertificate}
                    </a>
                )}
                {showInternetSecurityNumber && (
                    <a href={internetSecurityRegistrationLink} target="_blank" rel="noopener noreferrer">
                        {AppSettings.internetSecurityNumberText}
                    </a>
                )}
            </footer>
        </>
    );
};

export default Footer;
