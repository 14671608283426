import AppSettings from 'app/core/AppSettings';

const isAbsolute = (url: string): boolean => {
    return url.indexOf('://') > 0 || url.indexOf('//') === 0;
};

const isEnvirosuiteDomain = (url: string): boolean => {
    const urlHostname = getHostName(url);
    const envirosuiteHostname = getHostName(AppSettings.appUrl);

    return urlHostname && urlHostname.endsWith(envirosuiteHostname);
};

const isWhiteListed = (url: string): boolean => {
    return !isAbsolute(url) || isEnvirosuiteDomain(url);
};

/**
 * Will hard redirect to the provided url.
 * @param url the url to hard redirect to
 */
const changeLocation = (url: string) => {
    window.location.href = isWhiteListed(url) ? url : AppSettings.appUrl;
};

const removePortFromUrl = (url: string): string => {
    const hostnames: string[] = url.split(':');
    if (hostnames.length === 2) {
        return hostnames[0];
    }
    return url;
};

const removeSubDomain = (url: string): string => {
    const hostnames = url.split('.');
    if (hostnames.length > 2) {
        return `${hostnames[hostnames.length - 2]}.${hostnames[hostnames.length - 1]}`;
    }
    return url;
};

const getHostName = (url: string): string => {
    // Magic regex for extracting hostname: https://stackoverflow.com/a/8498629
    const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    let hostname: string = matches && matches[1];
    hostname = removePortFromUrl(hostname);
    hostname = removeSubDomain(hostname);
    return hostname;
};

export default { changeLocation, getHostName };
