import { createAction, handleActions } from 'redux-actions';
import { SubmissionError } from 'redux-form';

import RequestState from 'app/core/redux/RequestState';
import AuthenticationService from 'app/modules/authentication/AuthenticationService';
import IChangePasswordModel from './IChangePasswordModel';

import WindowService from 'app/core/services/WindowService';
import AppSettings from 'app/core/AppSettings';

const ActionTypes = {
    CHANGE_PASSWORD_REQUEST_STATE_CHANGE: 'es/auth/password/CHANGE_PASSWORD_REQUEST_STATE_CHANGE',
    VERIFY_PASSWORD_TOKEN_REQUEST_STATE_CHANGE: 'es/auth/password/VERIFY_PASSWORD_TOKEN_REQUEST_STATE_CHANGE',
    NEW_PASSWORD_TOKEN_REQUEST_STATE_CHANGE: 'es/auth/password/NEW_PASSWORD_TOKEN_REQUEST_STATE_CHANGE',
};

interface IChangePasswordState {
    password: string;
    passwordConfirm: string;
    messages: string[];
    passwordChangeRequestState: RequestState;
    verifyPasswordTokenRequestState: RequestState;
    generateNewPasswordTokenRequestState: RequestState;
}

const initialState: IChangePasswordState = {
    password: '',
    passwordConfirm: '',
    messages: [],
    passwordChangeRequestState: RequestState.Idle,
    verifyPasswordTokenRequestState: RequestState.Idle,
    generateNewPasswordTokenRequestState: RequestState.Idle,
};

//////////////////// RESET PASSWORD

const changePasswordRequestStateChange = createAction(ActionTypes.CHANGE_PASSWORD_REQUEST_STATE_CHANGE);

const verifyPasswordTokenRequestStateChange = createAction(
    ActionTypes.VERIFY_PASSWORD_TOKEN_REQUEST_STATE_CHANGE,
    (requestState: RequestState, messages: string[] = null) => ({ requestState, messages }),
);

const generateNewPasswordTokenRequestStateChange = createAction(
    ActionTypes.NEW_PASSWORD_TOKEN_REQUEST_STATE_CHANGE,
    (requestState: RequestState, messages: string[] = null) => ({ requestState, messages }),
);

function verifyPasswordToken(passwordToken) {
    return async dispatch => {
        dispatch(verifyPasswordTokenRequestStateChange(RequestState.Pending));

        const result = await AuthenticationService.verifyPasswordToken(passwordToken);

        if (result.isSuccess()) {
            dispatch(verifyPasswordTokenRequestStateChange(RequestState.Success));
        } else {
            dispatch(verifyPasswordTokenRequestStateChange(RequestState.Fail, result.messages));
        }
    };
}

function generateNewPasswordToken(userId: string) {
    return async dispatch => {
        dispatch(generateNewPasswordTokenRequestStateChange(RequestState.Pending));

        const result = await AuthenticationService.generateNewPasswordToken(userId);

        if (result.isSuccess()) {
            dispatch(generateNewPasswordTokenRequestStateChange(RequestState.Success));
        } else {
            dispatch(generateNewPasswordTokenRequestStateChange(RequestState.Fail));
            throw new SubmissionError({ _error: result.messages as any });
        }
    };
}

function changePassword({ password }: IChangePasswordModel, ...args) {
    const { username, passwordToken } = args[1]; // component props

    return async (dispatch, getState) => {
        const { authentication } = getState();
        const oldPassword = authentication.login.password;

        dispatch(changePasswordRequestStateChange(RequestState.Pending));

        const result = await AuthenticationService.changePassword(password, username, oldPassword, passwordToken);

        if (result.isSuccess()) {
            dispatch(changePasswordRequestStateChange(RequestState.Success));
        } else {
            dispatch(changePasswordRequestStateChange(RequestState.Fail));
            throw new SubmissionError({ _error: result.messages as any });
        }
    };
}

function handlePasswordChangeSuccess() {
    WindowService.changeLocation(AppSettings.appUrl);
}

//////////////////// REDUCER

const reducer = handleActions({
    [changePasswordRequestStateChange]: handleChangePasswordRequestStateChange,
    [verifyPasswordTokenRequestStateChange]: handleVerifyPasswordTokenRequestStateChange,
    [generateNewPasswordTokenRequestStateChange]: handleGenerateNewPasswordTokenRequestStateChange,
}, initialState);

function handleChangePasswordRequestStateChange(state, action) {
    return {
        ...state,
        passwordChangeRequestState: action.payload,
    };
}

function handleVerifyPasswordTokenRequestStateChange(state, action) {
    const { requestState, messages } = action.payload;

    return {
        ...state,
        verifyPasswordTokenRequestState: requestState,
        messages,
    };
}

function handleGenerateNewPasswordTokenRequestStateChange(state, action) {
    const { requestState, messages } = action.payload;

    return {
        ...state,
        generateNewPasswordTokenRequestState: requestState,
        messages,
    };
}

//////////////////// EXPORTS

export default reducer;
export { changePassword, handlePasswordChangeSuccess, verifyPasswordToken, generateNewPasswordToken, IChangePasswordState };

// For testing only
export const internal = {
    changePasswordRequestStateChange,
    verifyPasswordTokenRequestStateChange,
    generateNewPasswordTokenRequestStateChange,
};