import { createBrowserHistory } from 'history';
import AppSettings from 'app/core/AppSettings';
import startsWith from 'lodash/fp/startsWith';
import ReactGA from 'react-ga';
const history = createBrowserHistory({ basename: AppSettings.baseUrl });
import qs from 'query-string';

history.listen(location => {
    const { pathname } = location;
    if (!pathname) return;

    if (pathname !== '/') {
        ReactGA.pageview(pathname);
    }
});

export default history;

export function sanitizeQueryString(search: string): string {
    const queryString = qs.parse(search);
    if (!queryString) return '';

    let rootAppUrl = AppSettings.appUrl;
    if (rootAppUrl[rootAppUrl.length - 1] === '/') {
        rootAppUrl = rootAppUrl.slice(0, -1);
    }

    const targetValues = {} as { [key: string]: string };

    // Typically this gives a warning since we're not checking `hasOwnProperty` but `query-string.parse`
    // gives back a no-prototype object so this is the right way to iterate over its properties.
    //
    // tslint:disable-next-line
    for (const paramName in queryString) {
        let paramValue = queryString[paramName] as string;

        if (paramName.toLowerCase() === 'returnurl') {
            // Cater for cross-app redirects
            const returnUrl = raiseProtocol(rootAppUrl, paramValue);
            targetValues[paramName] = returnUrl;
            continue;
        }

        targetValues[paramName] = paramValue;
    }

    const targetQueryString = qs.stringify(targetValues);
    return (targetQueryString && `?${targetQueryString}`) || '';
}

/*
  Raise url protocol to same level as the rootUrl.
  Do nothing if rootUrl is not secure.
 */
function raiseProtocol(rootUrl: string, url: string): string {
    if (isSecureProtocol(rootUrl) && !isSecureProtocol(url)) {
        return url.replace('http', 'https');
    }
    return url;
}

function isSecureProtocol(url: string): boolean {
    return startsWith('https', url.toLowerCase());
}
