import { createl10n } from 'app/core/i18n';
import React from 'react';
import './Spinner.less';

import Throbber from 'react-spinkit';

interface IProps {
    message?: string;
    blank?: boolean;
}

const Spinner = (props: IProps) => {
    const { message, blank } = props;

    let spinnerMessage = message || null;

    if (!(spinnerMessage || blank)) {
        const l10n = createl10n('app/components/spinner');
        spinnerMessage = l10n('pleaseWait');
    }

    return (
        <div className="row">
            <div className="col-xs-12 text-center">
                <Throbber name="line-scale-pulse-out-rapid" color="#59cbe8" />
                {!blank && (
                    <h2 className="spinner-message">{spinnerMessage}</h2>
                )}
            </div>
        </div>
    );
};

export default Spinner;