import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import store from 'app/core/redux/store';
import { Router } from 'react-router';
import history from 'app/core/History';

import 'react-nprogress/nprogress.css';
import 'app/index.less';

import { Main } from 'app/shell';

document.title = 'Envirosuite';

ReactGA.initialize('UA-8575676-2', {
    testMode: location.hostname !== 'es2.envirosuite.com',
});

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Main />
        </Router>
    </Provider>,
    document.getElementById('root')
);
