import React from 'react';
import { ReactSVG } from 'react-svg';

import './InlineSVG.less';

interface IProps {
    path: string;
    className?: string;
}

const InlineSVG = ({ path, className }: IProps) => (
    <div className={`inline-svg ${className || ''}`}>
        <ReactSVG src={path}/>
    </div>
);

export default InlineSVG;