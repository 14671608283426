import qs from 'query-string';
import { createAction, handleActions } from 'redux-actions';
import { SubmissionError } from 'redux-form';

import AppSettings from 'app/core/AppSettings';
import WindowService from 'app/core/services/WindowService';
import AuthenticationService from 'app/modules/authentication/AuthenticationService';
import ILoginModel from './ILoginModel';
import RequestState from 'app/core/redux/RequestState';

import history from 'app/core/History';

const ActionTypes = {
    VERIFY_LOGIN_COOKIE_REQUEST_STATE_CHANGE: 'es/auth/login/VERIFY_LOGIN_COOKIE_REQUEST_STATE_CHANGE',
    LOGIN_REQUEST_STATE_CHANGE: 'es/auth/login/LOGIN_REQUEST_STATE_CHANGE',
};

interface ILoginState {
    username: string;
    password: string;
    token: string;
    useCaptcha: boolean;

    verifyingLoginCookieRequestState: RequestState;

    loginRequestState: RequestState;
    loginResponse: any;
}

const initialState: ILoginState = {
    username: '',
    password: '',
    token: '',
    useCaptcha: false,

    verifyingLoginCookieRequestState: RequestState.Idle,

    loginRequestState: RequestState.Idle,
    loginResponse: null,
};

//////////////////// LOGIN

const verifyingLoginCookieRequestStateChange = createAction(ActionTypes.VERIFY_LOGIN_COOKIE_REQUEST_STATE_CHANGE);
const loginRequestStateChange = createAction(
    ActionTypes.LOGIN_REQUEST_STATE_CHANGE,
    (requestState: RequestState, messages: string[] = null, useCaptcha?: boolean) => ({
        requestState,
        messages,
        useCaptcha,
    })
);

const verifyLoginCookie = () => async dispatch => {
    dispatch(verifyingLoginCookieRequestStateChange(RequestState.Pending));
    const verifyLoginCookieResponse = await AuthenticationService.isLoggedIn();
    const isLoggedIn = !verifyLoginCookieResponse.isUnauthorized() && verifyLoginCookieResponse.data;

    if (isLoggedIn) {
        WindowService.changeLocation(AppSettings.appUrl);
    } else {
        dispatch(verifyingLoginCookieRequestStateChange(RequestState.Fail));
    }
};

const login = (formData: ILoginModel) => async dispatch => {
    dispatch(loginRequestStateChange(RequestState.Pending));

    const loginResponse = await AuthenticationService.login(formData);

    if (!loginResponse.isSuccess()) {
        dispatch(loginRequestStateChange(RequestState.Fail, loginResponse.messages, loginResponse.data?.useCaptcha));
        throw new SubmissionError({ _error: loginResponse.messages });
    } else {
        if (loginResponse.data.isPasswordChangeRequired) {
            dispatch(history.push('/change-password'));
        } else {
            const queryString = qs.parse(history.location.search);
            const [returnUrl] = Object.keys(queryString)
                .filter(key => key.toLowerCase() === 'returnurl')
                .map(key => queryString[key]);
            WindowService.changeLocation(returnUrl || AppSettings.appUrl);
        }
    }
};

//////////////////// REDUCER

const reducer = handleActions(
    {
        '@@redux-form/CHANGE': handleFormChange,
        [verifyingLoginCookieRequestStateChange]: handleVerifyLoginCookieRequestStateChange,
        [loginRequestStateChange]: handleLoginRequestStateChange,
    },
    initialState
);

function handleVerifyLoginCookieRequestStateChange(state, action) {
    return {
        ...state,
        verifyingLoginCookieRequestState: action.payload,
    };
}

function handleFormChange(state, action) {
    const { field, form } = action.meta;

    if (form === 'login' || form === 'forgotPassword') {
        // Hold on to the username and password values as they're needed in subsequent forms
        if (field === 'username' || field === 'password') {
            return {
                ...state,
                [field]: action.payload,
            };
        }
    }

    return state;
}

function handleLoginRequestStateChange(state, action) {
    const { requestState, messages, useCaptcha } = action.payload;

    if (useCaptcha != null) {
        return {
            ...state,
            loginRequestState: requestState,
            messages,
            useCaptcha,
        };
    }
    return {
        ...state,
        loginRequestState: requestState,
        messages,
    };
}

//////////////////// EXPORTS

export default reducer;
export { verifyLoginCookie, login, ILoginState };

// For testing only
export const internal = {
    verifyingLoginCookieRequestStateChange,
    loginRequestStateChange,
};
