import { createl10n } from 'app/core/i18n';
import React from 'react';

import classNames from 'classnames';
import { InlineSVG } from 'app/components';
import './ChangePassword.less';

import * as assets from './assets';

import {
    validateMinLength,
    validateContainsLowercaseLetter,
    validateContainsUppercaseLetter,
    validateContainsSpecialCharacter,
    validateContainsNumber,
} from './ChangePassword.validator';

const l10n = createl10n('app/modules/authentication/components/change-password');

const ChangePasswordCriteria = ({ password }) => {
    return (
        <div className="passwordCriteria">
            {renderItem(validateMinLength(password), l10n('fields.passwordCriteria.minLength'))}
            {renderItem(validateContainsLowercaseLetter(password), l10n('fields.passwordCriteria.lowerCase'))}
            {renderItem(validateContainsUppercaseLetter(password), l10n('fields.passwordCriteria.upperCase'))}
            {renderItem(validateContainsNumber(password), l10n('fields.passwordCriteria.number'))}
            {renderItem(validateContainsSpecialCharacter(password), l10n('fields.passwordCriteria.specialCharacter'))}
        </div>
    );
};

function renderItem(isPasswordValid, message) {
    const svgClassNames = classNames('svg', {
        svgPass: isPasswordValid,
        svgFail: !isPasswordValid,
    });

    // We're loading both SVGs here so that the UI doesn't "jump" when switching between the cross/tick.
    const passClassNames = classNames(svgClassNames, { hidden: !isPasswordValid });
    const failClassNames = classNames(svgClassNames, { hidden: isPasswordValid });

    return (
        <div className="change-password">
            <InlineSVG path={assets.Pass} className={passClassNames} />
            <InlineSVG path={assets.Fail} className={failClassNames} />
            {message}
        </div>
    );
}

export default ChangePasswordCriteria;