import React from 'react';
import './ErrorList.less';

const ErrorList = ({ errors, heading }: { errors: string|string[], heading?: string }) => {
    if (!errors || !errors.length) {
        return null;
    }
    return (
        <div className="errorBox">
            {heading && (<h4>{heading}</h4>)}
            {errors.length === 1 ? renderSingleError(errors[0]) : renderMultipleErrors(errors)}
        </div>
    );
};

function renderSingleError(error) {
    return (
        <span dangerouslySetInnerHTML={{ __html: error }} />
    );
}

function renderMultipleErrors(errors) {
    return (
        <ul>
            {errors.map((error, index) => <li key={index} dangerouslySetInnerHTML={{ __html: error }} />)}
        </ul>
    );
}

export default ErrorList;
