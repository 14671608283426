import { createl10n } from 'app/core/i18n';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import AppSettings from '../../../../core/AppSettings';

import { ActionButton, FluidContainer, LabelledTextBox, ErrorList } from 'app/components';
import { login, ILoginState } from './Login.redux';
import validate from './Login.validator';

import RequestState from 'app/core/redux/RequestState';
import ILoginModel from './ILoginModel';

interface IPropsFromState {
    initialValues: {
        username: string;
    };

    useCaptcha: boolean;
    disabled: boolean;
}

interface IPropsFromDispatch {
    handleLogin: (loginData: ILoginModel) => any;
}

const l10n = createl10n('app/modules/authentication/components/login');

type FormProps = InjectedFormProps<ILoginModel, IPropsFromState & IPropsFromDispatch>;

export const LoginForm = ({
    disabled,
    handleSubmit,
    handleLogin,
    error: errors,
    change,
    useCaptcha,
}: IPropsFromState & IPropsFromDispatch & FormProps) => {
    const captchaChange = (value: string) => {
        change('token', value);
    };

    return (
        <FluidContainer heading={l10n('heading')}>
            <form onSubmit={handleSubmit(handleLogin)}>
                <Field
                    name="username"
                    component={LabelledTextBox}
                    label={l10n('fields.username.label')}
                    placeholder={l10n('fields.username.placeholder')}
                    disabled={disabled}
                    type="text"
                    autocorrect="off"
                    autocapitalize="none"
                />

                <Field
                    name="password"
                    component={LabelledTextBox}
                    label={l10n('fields.password.label')}
                    placeholder={l10n('fields.password.placeholder')}
                    disabled={disabled}
                    type="password"
                />
                <div className="row">
                    <div className="col-xs-6">
                        <Field
                            id="rememberMe"
                            name="rememberMe"
                            component="input"
                            type="checkbox"
                            disabled={disabled}
                        />

                        <label htmlFor="rememberMe" />

                        <label htmlFor="rememberMe">{l10n('actions.rememberMe')}</label>
                    </div>

                    <div className="col-xs-6 text-right">
                        <Link to="/forgot-password">{l10n('actions.forgotPassword')}</Link>
                    </div>
                </div>

                <ErrorList heading={l10n('errors.heading')} errors={errors} />
                {useCaptcha && <ReCAPTCHA sitekey={AppSettings.reCaptchaKey} onChange={captchaChange} />}

                <ActionButton label={l10n('actions.login.default')} disabled={disabled} />

                {AppSettings.auth0Disabled !== 'true' && (
                    <div className="row">
                        <div className="col-xs-12 text-center sso-link-column">
                            <a href={`${AppSettings.ssoHandshakeUrl}${window.location.search}`}>
                                {l10n('actions.loginWithSSO')}
                            </a>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-xs-12 text-center">
                        <span>{l10n('labels.needHelp')}&nbsp;</span>
                        <a href="https://support.envirosuite.com/ ​">{l10n('actions.contactSupport')}</a>
                    </div>
                </div>
            </form>
        </FluidContainer>
    );
};

const LoginFormDecorated = reduxForm<ILoginModel, IPropsFromState & IPropsFromDispatch>({
    form: 'login',
    validate,
})(LoginForm);

function mapStateToProps(state: { authentication: { login: ILoginState } }): IPropsFromState {
    const { login } = state.authentication;

    return {
        initialValues: {
            username: login.username,
        },
        useCaptcha: login.useCaptcha,
        disabled: login.loginRequestState === RequestState.Pending,
    };
}

export default connect(mapStateToProps, { handleLogin: login })(LoginFormDecorated);
