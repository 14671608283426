import { createl10n } from 'app/core/i18n';

const l10n = createl10n('app/core/services');

export default class HttpResponse {
    public data: unknown;
    public messages?: string[];

    constructor(public status: number, private fetchResponse, public error = null) {
        this.status = status;
        this.error = error;

        this.data = fetchResponse && fetchResponse.hasOwnProperty('data')
            ? fetchResponse.data
            : fetchResponse;

        this.messages = this.isError()
            ? [l10n('httpResponse.errors.server')]
            : fetchResponse && fetchResponse.messages && fetchResponse.messages.map(message => message.content) || [];
    }

    public isSuccess = () => {
        return this.status >= 200 && this.status < 300;
    }

    public isError = () => {
        return this.status >= 500 || !!this.error;
    }

    public isUnauthorized = () => {
        return this.status === 401;
    }

    public isForbidden = () => {
        return this.status === 403;
    }
}