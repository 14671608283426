import { createl10n } from 'app/core/i18n';
import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { FSA } from 'flux-standard-action';

import { ActionButton, LabelledTextBox, LinkButton, ErrorList, FluidContainer } from 'app/components';
import { resetPassword, clearForm, IForgotPasswordState } from './ForgotPassword.redux';
import { ILoginState } from 'app/modules/authentication/components/login/Login.redux';

import validate from './ForgotPassword.validator';

import RequestState from 'app/core/redux/RequestState';
import IForgotPasswordModel from './IForgotPasswordModel';

const l10n = createl10n('app/modules/authentication/components/forgot-password');

interface IPropsFromState {
    initialValues: {
        username: string;
    };
    disabled: boolean;
    hasPasswordBeenReset: boolean;
}

interface IPropsFromDispatch {
    handleResetPassword: (formValues: IForgotPasswordModel) => any;
    handleClearForm: () => FSA<string, RequestState>;
}

type FormProps = InjectedFormProps<IForgotPasswordModel, IPropsFromState & IPropsFromDispatch>;

const ForgotPasswordFormSubmitted = (): JSX.Element => {
    return (
        <FluidContainer
            heading={l10n('heading.complete')}
            instructions={l10n('instructions.complete')}
        >

            <LinkButton to="/login" label={l10n('actions.returnToLogin')} />
        </FluidContainer>
    );
};

export class ForgotPasswordForm extends React.PureComponent<IPropsFromState & IPropsFromDispatch & FormProps> {
    public componentDidMount(): void {
        this.props.handleClearForm();
    }

    public render(): JSX.Element {
        const { hasPasswordBeenReset } = this.props;
        if (hasPasswordBeenReset) {
            return <ForgotPasswordFormSubmitted/>;
        }

        const { disabled, handleSubmit, handleResetPassword, error } = this.props;

        return (
            <FluidContainer
                heading={l10n('heading.default')}
                instructions={l10n('instructions.default')}
            >
                <form onSubmit={handleSubmit(handleResetPassword)}>
                    <Field
                        name="username"
                        component={LabelledTextBox}
                        label={l10n('fields.username.label')}
                        placeholder={l10n('fields.username.placeholder')}
                        disabled={this.props.disabled || this.props.hasPasswordBeenReset}
                        type="text"
                        autocorrect="off"
                        autocapitalize="none"
                    />

                    <Field
                        name="emailAddress"
                        component={LabelledTextBox}
                        label={l10n('fields.emailAddress.label')}
                        placeholder={l10n('fields.emailAddress.placeholder')}
                        disabled={this.props.disabled || this.props.hasPasswordBeenReset}
                        type="email"
                    />

                    <ErrorList heading={l10n('errors.heading')} errors={error} />

                    <ActionButton
                        label={l10n('actions.resetPassword.default')}
                        disabled={disabled}
                    />

                    <LinkButton to="/login" label={l10n('actions.returnToLogin')} disabled={disabled} />
                </form>
            </FluidContainer>
        );
    }
}

const ForgotPasswordFormDecorated = reduxForm<IForgotPasswordModel, IPropsFromState & IPropsFromDispatch>({
    form: 'forgotPassword',
    validate,
})(ForgotPasswordForm);

const mapStateToProps = (state: { authentication: { login: ILoginState, password: { forgot: IForgotPasswordState } } }): IPropsFromState => {
    const { forgot } = state.authentication.password;
    const { login } = state.authentication;

    return {
        initialValues: {
            username: login.username,
        },
        disabled: forgot.passwordResetRequestState === RequestState.Pending,
        hasPasswordBeenReset: forgot.passwordResetRequestState === RequestState.Success,
    };
};

export default connect(mapStateToProps, {
    handleResetPassword: resetPassword,
    handleClearForm: clearForm,
})(ForgotPasswordFormDecorated);
