import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import middleware from './middleware';
import AppSettings from 'app/core/AppSettings';

// Reducers
import { reducer as formReducer, FormStateMap } from 'redux-form';

import loginReducer, { ILoginState } from 'app/modules/authentication/components/login/Login.redux';
import forgotPasswordReducer, { IForgotPasswordState } from 'app/modules/authentication/components/forgot-password/ForgotPassword.redux';
import changePasswordReducer, { IChangePasswordState } from 'app/modules/authentication/components/change-password/ChangePassword.redux';

// Redux DevTools
const composeEnhancers = AppSettings.isDevelopment && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface IGlobalState {
    authentication: {
        login: ILoginState;
        password: {
            forgot: IForgotPasswordState;
            change: IChangePasswordState;
        }
    }
    form: FormStateMap
}

const rootReducer = combineReducers({
    authentication: combineReducers({
        login: loginReducer,
        password: combineReducers({
            forgot: forgotPasswordReducer,
            change: changePasswordReducer,
        }),
    }),
    form: formReducer,
});

export default createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware)),
);