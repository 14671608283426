import * as url from 'modules/url';

import 'modules/polyfills';

interface IAppSettings {
    appUrl: string;

    /**
     * Base URL for the ES2 internal API
     */
    apiUrl: string;

    ssoHandshakeUrl: string;

    auth0Disabled: string;

    /**
     * Base URL for the ES2 public API
     */
    publicApiUrl: string;

    /**
     * The baseUrl tells the react router how much of the URL to "ignore" when
     * figuring out the location.
     *
     * When developing using the webpack dev server, our base path is '/' - but
     * when running through IIS (both on a local machine or when deployed) the
     * base path shifts to '/auth/'.
     */
    baseUrl: string;
    isDevelopment: boolean;
    environment: string;
    icpCertificate: string;
    internetSecurityNumber: string;
    internetSecurityNumberText: string;
    languagesExplicitlyAllowed: string;
    reCaptchaKey: string;
    useRecaptchaNet: boolean;

    buildAppUrl(endpoint: string): string;
    buildApiUrl(endpoint: string): string;
    buildPublicApiUrl(endpoint: string): string;
}

// These settings will get substituted out by Octopus
let injectedAppSettings = {
    appUrl: 'https://omnis.envirosuite.com',
    apiUrl: 'https://es2.envirosuite.com/api',
    ssoHandshakeUrl: 'https://es2.envirosuite.com/api/security/auth0',
    auth0Disabled: 'false',
    publicApiUrl: 'https://app.envirosuite.com/api',
    environment: 'Production',
    icpCertificate: '',
    internetSecurityNumber: '',
    internetSecurityNumberText: '',
    languagesExplicitlyAllowed: '#{languagesExplicitlyAllowed}',
    reCaptchaKey: '6Ld8OtEaAAAAAO9JFDSNn6q-hoFvJ7Xx1FlprmUI',
    useRecaptchaNet: 'false'.toLowerCase() === 'true',
};

// Cater for development where Octopus doesn't run, hence no substitution
if (!injectedAppSettings.appUrl.startsWith('http')) {
    injectedAppSettings = {
        appUrl: 'http://localhost:3000/',
        apiUrl: 'http://localhost:61000/api',
        ssoHandshakeUrl: 'https://localhost:7123/security/cognito',
        auth0Disabled: 'false',
        publicApiUrl: 'http://localhost:60000/api',
        environment: 'Development',
        icpCertificate: '',
        internetSecurityNumber: '',
        internetSecurityNumberText: '',
        languagesExplicitlyAllowed: '',
        reCaptchaKey: '',
        useRecaptchaNet: false,
    };
}

const location = window.location.href.toLowerCase();
const baseUrl = location.indexOf('/auth') >= 0 ? '/auth/' : '/';

const createUrlBuilder = (urlBase: string) => (endpoint: string) => url.join(urlBase, endpoint);

const AppSettings: IAppSettings = {
    ...injectedAppSettings,
    baseUrl,
    isDevelopment: location.indexOf('localhost') >= 0,

    buildAppUrl: createUrlBuilder(baseUrl),
    buildApiUrl: createUrlBuilder(injectedAppSettings.apiUrl),
    buildPublicApiUrl: createUrlBuilder(injectedAppSettings.publicApiUrl),
};

export default AppSettings;

declare global {
    interface Window {
        recaptchaOptions: RecaptchaOptions;
    }
}
window.recaptchaOptions = { useRecaptchaNet: AppSettings.useRecaptchaNet };

interface RecaptchaOptions {
    useRecaptchaNet: boolean;
}
