import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

import { CSSTransition } from 'react-transition-group';
import { WrappedFieldProps, WrappedFieldMetaProps } from 'redux-form';

import './LabelledTextBox.less';

const FieldError = (props: { meta: WrappedFieldMetaProps }) => {
    const { error, touched } = props.meta;
    if (!(error && touched)) return null;

    const errors = [].concat(error);

    if (errors.length === 1) {
        return (
            <span className="help-block">
                {errors[0]}
            </span>
        );
    }

    return (
        <span className="help-block">
            <ul>
                {errors.map(error => (
                    <li key={error}>{error}</li>
                ))}
            </ul>
        </span>
    );
};

const LabelledTextBox = (props: InputHTMLAttributes<HTMLInputElement> & WrappedFieldProps) => {
    const hasError = !!(props.meta.error && props.meta.touched);

    return (
        <div className={classNames('form-group', { 'has-error': hasError })}>
            <input
                {...props.input}
                type={props.type}
                className="form-control"
                placeholder={props.placeholder || ''}
                disabled={props.disabled}
            />

            <CSSTransition
                key={props.name}
                classNames="fade"
                timeout={250}
                in={hasError}
            >
                <FieldError meta={props.meta} />
            </CSSTransition>
        </div>
    );
};

export default LabelledTextBox;