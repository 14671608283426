'use strict';

import object_assign from 'object-assign';
import { enable } from 'promise/lib/rejection-tracking';

//import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'whatwg-fetch';

if (typeof Promise === 'undefined') {
    // Rejection tracking prevents a common issue where React gets into an
    // inconsistent state due to an error, but it gets swallowed by a Promise,
    // and the user has no idea what causes React's erratic future behavior.
    enable();
    window.Promise = require('promise/lib/es6-extensions.js');
}

// Object.assign() is commonly used with React and is not present in IE 11.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = object_assign;
