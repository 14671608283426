import { createl10n } from 'app/core/i18n';
import { IGlobalState } from 'app/core/redux/store';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import history from 'app/core/History';

import qs from 'query-string';
import { ActionButton, ErrorList, FluidContainer, LabelledTextBox, LinkButton, Spinner } from 'app/components';

import {
    verifyPasswordToken,
    changePassword,
    generateNewPasswordToken,
    handlePasswordChangeSuccess,
} from 'app/modules/authentication/components/change-password/ChangePassword.redux';

import ChangePasswordCriteria from './ChangePasswordCriteria';
import validate from './ChangePassword.validator';
import RequestState from 'app/core/redux/RequestState';
import AppSettings from 'app/core/AppSettings';
import IChangePasswordModel from './IChangePasswordModel';

const formSelector = formValueSelector('changePassword');
const l10n = createl10n('app/modules/authentication/components/change-password');

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export type PasswordTokenInvalidProps = Pick<StateProps & DispatchProps, 'disabled' | 'generateNewPasswordToken'> & {
    errors: string[];
};

export const PasswordTokenInvalid = ({
    disabled,
    errors,
    generateNewPasswordToken,
}: PasswordTokenInvalidProps): JSX.Element => {
    return (
        <FluidContainer heading={l10n('heading.failed')}>
            <ErrorList key="error-list" errors={errors} />

            <ActionButton
                label={l10n('actions.generateNewPasswordToken.default')}
                disabled={disabled}
                onClick={generateNewPasswordToken}
            />

            <LinkButton label={l10n('actions.login')} to="/login" disabled={disabled} />
        </FluidContainer>
    );
};

export const PasswordTokenGenerated = (): JSX.Element => {
    return (
        <FluidContainer
            heading={l10n('heading.success')}
            instructions={l10n('instructions.success.generateNewPasswordToken')}
        >
            <LinkButton to="/login" label={l10n('actions.login')} />
        </FluidContainer>
    );
};

export const ChangePasswordFormSubmitted = (): JSX.Element => {
    return (
        <FluidContainer heading={l10n('heading.success')} instructions={l10n('instructions.success.changePassword')}>
            <div className="row">
                <div className="col-xs-12 text-center">
                    <a href={AppSettings.appUrl}>{l10n('actions.continue')}</a>
                </div>
            </div>
        </FluidContainer>
    );
};

type FormProps = InjectedFormProps<IChangePasswordModel, StateProps & DispatchProps>;

export class ChangePasswordForm extends React.PureComponent<StateProps & DispatchProps & FormProps> {
    public async componentDidMount() {
        const { verifyPasswordToken, passwordToken } = this.props;

        if (passwordToken) {
            await verifyPasswordToken(passwordToken);
        }
    }

    public render() {
        const { disabled, verifyingPasswordToken, hasNewPasswordTokenBeenSent, hasPasswordBeenChanged } = this.props;
        if (verifyingPasswordToken) {
            return <Spinner />;
        }

        if (!this.hasUsernameOrPasswordToken()) {
            return (
                <PasswordTokenInvalid
                    errors={[l10n('instructions.failedTokenInvalid')]}
                    disabled={disabled}
                    generateNewPasswordToken={this.generateNewPasswordToken}
                />
            );
        }

        if (!this.isPasswordTokenValid()) {
            if (hasNewPasswordTokenBeenSent) {
                return <PasswordTokenGenerated />;
            }

            return (
                <PasswordTokenInvalid
                    errors={[l10n('instructions.failedTokenExpired')]}
                    disabled={disabled}
                    generateNewPasswordToken={this.generateNewPasswordToken}
                />
            );
        }

        if (hasPasswordBeenChanged) {
            return <ChangePasswordFormSubmitted />;
        }

        return this.renderForm();
    }

    private renderForm = (): JSX.Element => {
        const { password, handleSubmit, handleChangePassword, disabled, error } = this.props;

        return (
            <FluidContainer heading={l10n('heading.default')} instructions={l10n('instructions.default')} cols={8}>
                <form onSubmit={handleSubmit(handleChangePassword)}>
                    <Field
                        name="password"
                        component={LabelledTextBox}
                        label={l10n('fields.password.label')}
                        placeholder={l10n('fields.password.placeholder')}
                        disabled={disabled}
                        type="password"
                        autocomplete="off"
                    />

                    <Field
                        name="passwordConfirm"
                        component={LabelledTextBox}
                        label={l10n('fields.passwordConfirm.label')}
                        placeholder={l10n('fields.passwordConfirm.placeholder')}
                        disabled={disabled}
                        type="password"
                        autocomplete="off"
                    />

                    <ErrorList heading={l10n('errors.heading')} errors={error} />

                    <ActionButton label={l10n('actions.changePassword.default')} disabled={disabled} />
                    <LinkButton label={l10n('actions.login')} to="/login" disabled={disabled} />
                </form>

                <ChangePasswordCriteria password={password} />
            </FluidContainer>
        );
    };

    private hasUsernameOrPasswordToken = (): boolean => !!(this.props.passwordToken || this.props.username);
    private isPasswordTokenValid = (): boolean => !this.props.passwordToken || this.props.isValidPasswordToken;
    private generateNewPasswordToken = () => this.props.generateNewPasswordToken(this.props.passwordToken);
}

const ChangePasswordFormDecorated = reduxForm<IChangePasswordModel, StateProps & DispatchProps>({
    form: 'changePassword',
    validate,
})(ChangePasswordForm);

const mapStateToProps = (state: IGlobalState) => {
    const { change } = state.authentication.password;
    const passwordToken = qs.parse(history.location.search).token as string;

    return {
        passwordToken,
        username: state.authentication.login.username,
        password: formSelector(state, 'password'),
        passwordConfirm: formSelector(state, 'passwordConfirm'),
        hasPasswordBeenChanged: change.passwordChangeRequestState === RequestState.Success,
        verifyingPasswordToken: change.verifyPasswordTokenRequestState === RequestState.Pending,
        isValidPasswordToken: change.verifyPasswordTokenRequestState === RequestState.Success,
        generatingNewPasswordToken: change.generateNewPasswordTokenRequestState === RequestState.Pending,
        hasNewPasswordTokenBeenSent: change.generateNewPasswordTokenRequestState === RequestState.Success,
        messages: change.messages,

        disabled:
            change.passwordChangeRequestState === RequestState.Pending ||
            change.verifyPasswordTokenRequestState === RequestState.Pending ||
            change.generateNewPasswordTokenRequestState === RequestState.Pending,
    };
};

const mapDispatchToProps = {
    handleChangePassword: changePassword,
    handlePasswordChangeSuccess,
    verifyPasswordToken,
    generateNewPasswordToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordFormDecorated);
