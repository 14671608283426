/**********************************************************************************************************************
 *
 * Typically we use url-join for this but it's not IE 11 friendly.
 *
 **********************************************************************************************************************/

import startsWith from 'lodash/fp/startsWith';
import endsWith from 'lodash/fp/endsWith';

function join(...args: string[]): string {
    if (!args.length) {
        return '';
    }

    return args.reduce((memo, url) => {
        if (!memo) {
            return url;
        }

        const memoEndsWithSlash = endsWith('/', memo);
        const urlStartsWithSlash = startsWith('/', url);

        const tokens = [memo];

        if (memoEndsWithSlash && urlStartsWithSlash) {
            tokens.push(url.substring(1));
        } else if (!memoEndsWithSlash && !urlStartsWithSlash) {
            tokens.push('/');
            tokens.push(url);
        } else {
            tokens.push(url);
        }

        return tokens.join('');
    });
}

export { join };